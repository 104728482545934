<template>
  <div
    class=""
    style="
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;">
    <div class="toggle-option" v-if="typeuid===1||typeuid===2">
      <div data-bs-toggle="dropdown" aria-expanded="false">
        <span style="position: relative;"><i class="bi bi-bell"></i> <b class="notification_number">{{ notificaitonCount }}</b></span>
      </div>
      <ul class="dropdown-menu notification-dropdown" aria-labelledby="dropdownMenuButton2">
        <li v-for="res in notificaionArr" :key="res" @click="readNotifications(res.uid)">{{ res.content }}
        </li>
        </ul>
    </div>

    <div class="profile-dropdown">
      <div data-bs-toggle="dropdown" aria-expanded="false" class="pr-name">
        <span class="profile-img"><i class="bi bi-person-circle"></i></span>
        <span class="us-name">{{ userName }}</span>
      </div>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <router-link
            :to="{ name: profileUrl }"
            class="dropdown-item"
            style="cursor: pointer"
            ><i class="bi bi-person"></i> Profile</router-link
          >
        </li>
        <li>
          <a
            class="dropdown-item"
            style="cursor: pointer"
            v-on:click="userLogout"
            ><i class="bi bi-box-arrow-right"></i> Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import callService from "@/services/Service";
const { getData, getByID, postData,putData } = callService();
export default {
  name: "Common",
  data() {
    return {
      intervalId: null,
      typeuid:null,
      notificaitonCount: null,
      notificaionArr: [],
      userName: JSON.parse(localStorage.getItem("LogIn")).user_fname,
      profileUrl: null,
    };
  },
  async mounted() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =
        type_uid === 1
          ? "AdminProfile"
          : type_uid === 2
          ? "ManagerProfile"
          : type_uid === 3
          ? "Profile"
          : "/login";
      this.typeuid=type_uid;
      this.profileUrl = redirction;
    }
  },
  created() {
    if(JSON.parse(localStorage.getItem("LogIn")).user_uid){
      this.intervalId = setInterval(this.getNotification,5000); // 5000ms = 5 seconds
    }
    
  },
  methods: {
    async readNotifications(uid){
      const resData = await postData("user/updateNotification/",{uid:uid});
    },
    async getNotification(){
      try {
      const notificationResponse = await getData("user/getNotification");
      if (
        notificationResponse.status === 200 &&
        notificationResponse.data.statuscode === 1
      ) {
        this.notificaionArr = notificationResponse.data.result;
        this.notificaitonCount = notificationResponse.data.result.length;
        //console.log("detail",this.notificaionArr);
      }
    } catch (e) {
      this.error = e;
    }
    },
    userLogout() {
      localStorage.clear();
      this.$router.push({ name: "login" });
      //this.updateLogoutStatus();
    },
    async updateLogoutStatus() {
      const user_uid = JSON.parse(localStorage.getItem("LogIn")).user_uid;
      try {
        const resData = await postData("login/updateLoginStatus", {
          user_uid: user_uid,
          login_status: 0,
        });
        if (resData.status === 200 && resData.data.statuscode === 1) {
          localStorage.clear();
          this.$router
            .push({
              name: "login",
            })
            .then(() => {
              this.$router.go();
            });
        }
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>


