<template>
  <div>
    <template v-if="$route.meta.allowAnonymous == true">
      <Header></Header>
      <Navbar></Navbar>
      <main>
        <router-view />
        <div class="sidebar sidAudioCall">
          <div class="sidebar-heading">
            <span
              class="bi-x-lg close-sidebar"
              @click="closeAudioSidebar()"
            ></span>
            <h4>Web Phone</h4>
          </div>
          <!-- <Dialer></Dialer> -->
        </div>
        <div class="sidebar sidVideoCall">
          <div class="sidebar-heading">
            <span
              class="bi-x-lg close-sidebar"
              @click="closeVideoSidebar()"
            ></span>
            <h4>Video Call</h4>
          </div>
          <!-- <Consultation></Consultation> -->
          <div
            class="videoZoom"
            title="Show Full Screen"
            @click="fullScreenVideo()">
            <span class="bi-arrow-left-circle-fill"></span>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </template>
    <template v-if="!$route.meta.allowAnonymous">
      <router-view />
    </template>
  </div>
</template>
<script>
import $ from "jquery";
//Bootstrap 5
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Custom CSS
// import "./assets/css/style.css";
// Responsive CSS
import "./assets/css/responsive.css";
// bootstrap icons
import "./assets/css/bootstrap-icons.min.css";
import Header from "./views/include/Header.vue";
import Footer from "./views/include/Footer.vue";
import Navbar from "./views/include/Navbar.vue";
//import Consultation from "./views/Consultation.vue";
//import Dialer from "./views/include/Dialer.vue";
export default {
  components: {
    Navbar,
    Header,
    Footer,
  //  Consultation,
    //Dialer,
  },
  data() {
    return {
      profileUrl: null,
      successMessage: null,
      loginUser: JSON.parse(localStorage.getItem("LogIn")),
    };
  },
  name: "App",
  setup() {
  },
  methods:{
    closeAudioSidebar() {
      $(".sidAudioCall").toggleClass("sidebarDailerActive");
    },
    closeVideoSidebar() {
      $(".sidVideoCall").toggleClass("sidebarVideoActive");
    },
    fullScreenVideo() {
      $(".sidVideoCall").toggleClass("sidebarVideoFull");
      $(".bi-arrow-left-circle-fill").toggleClass("bi-arrow-right-circle-fill");
    },
  },
};
</script>
<style scoped>
@import "./assets/css/style.css";
</style>