<template>
  <section class="inner-page-outer">
    <div class="page-heading">
      <h1>Contact from Hubspot</h1>
    </div>
    <div class="customTableouter">
      <table id="leadid" class="table table-striped">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ firstname }}</td>
            <td>{{ lastname }}</td>
            <td>{{ email }}</td>
            <td>{{ createdate }}</td>
          </tr>
        </tbody>
      </table>
      <br/>
      <br/>  
      <br/>  
      <p>Contact from FreshDesk</p>
      <table id="freshdeskid" class="table table-striped">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataTable" :key="item">
            <td>{{ item.first_name }}</td>
            <td>{{ item.last_name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.updated_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import callService from "@/services/Service";
const { getData, getByID, postData, putData } = callService();
import $ from "jquery";

export default {
  name: "Hubsport",
   data: () => {
    return {
      firstname:null,
      lastname:null,
      email:null,
      createdate:null,
      dataTable: null,
      arr:null,
      successMessage: null,
    };
  },

  async mounted(){
    try {
      const response = await getData("/hubspotContactsApi");
      if(response.status === 200 && response.data.statuscode === 1){
        const res = response.data.result.results[0].properties;
        this.firstname=res.firstname;
        this.lastname=res.lastname;
        this.email=res.email;
        this.createdate=res.createdate;
      }
    }catch(e){
      this.error = e;
    }

    try {
      const response = await getData("/freshdeskDealsApi");
      if (response.status === 200 && response.data.statuscode === 1) {
        this.dataTable = response.data.result;
        console.log("XYZ",this.dataTable);
       
      }
    }catch(e){
      this.error = e;
    }

    $("#leadid").DataTable({
      paging: false,
      autoFill: false,
    });
    $("#freshdeskid").DataTable({
      paging: false,
      autoFill: false,
    });
  },
    
};
</script>
