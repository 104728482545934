<template>
  <div class="loginOuter">
    <div class="alert alert-danger" :class="{ 'alert-active': errorMessage != null }">
      <strong>Error!</strong> {{ errorMessage }}
    </div>
    <div class="loginBox">
      <div class="loginImageCol">
        <img alt="" src="../assets/img/login-image.jpg" />
      </div>
      <div class="loginFormCol">
        <div class="logo">
          <img alt="" src="../assets/img/lvc-logo.png"/>
        </div>
        <div class="title"><h2>Login</h2></div>
        <form class="loginField" @submit.prevent="submitForm">
          <div class="inputCol">
            <div
              class="loginFeild"
              :class="{ 'inccorect-feild': v$.type_uid.$error }">
              <select class="custom-field" v-model="formFields.type_uid">
                <option
                  v-for="(item, index) in userGroup"
                  :key="index"
                  v-bind:value="item.type_uid"
                >
                  {{ item.type_user }}
                </option>
              </select>
              <i class="bi bi-chevron-down feild-icon"></i
              ><label>Group Type<span class="danger">*</span></label>
            </div>
            <p class="danger error-show">Error! This field is required.</p>
          </div>
          <div class="inputCol">
            <div
              class="loginFeild"
              :class="{ 'inccorect-feild': v$.user_name.$error }"
            >
              <input
                type="text"
                v-model="formFields.user_name"
                class="custom-field"
              /><i class="bi bi-person feild-icon"></i
              ><label>Username<span class="danger">*</span></label>
            </div>
            <p class="danger error-show">Error! Username is Required.</p>
          </div>
          <div class="inputCol">
            <div
              class="loginFeild" :class="{ 'inccorect-feild': v$.password.$error }">
              <input
                type="password"
                v-model="formFields.password"
                class="custom-field"
              /><i class="bi bi-lock feild-icon"></i
              ><label>Password<span class="danger">*</span></label>
            </div>
            <p class="danger error-show">
              Error! Your password is missing or entered incorrectly.
            </p>
          </div>
          <div class="inputCol">
            <div class="loginFeild" >
              <input type="checkbox" v-model="rememberMe" />
              <!-- <i class="bi bi-chevron-down feild-icon"></i> -->
              <label>&nbsp; Remember Me </label>
            </div>
          </div>
          <div class="customButton">
            <button class="login-button">
              Login <span class="bi bi-arrow-right"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();

export default {
  name: "Login",
  data() {
    return {
      rememberMe: false,
      userGroup: [
        { type_uid: 1, type_user: "Administrator" },
        { type_uid: 2, type_user: "Patient Manager" },
        { type_uid: 3, type_user: "Assistant" },
      ],
      userType: [],
      errorMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      type_uid: "",
      user_name: "",
      password: "",
    });

    const rules = {
      type_uid: {
        required,
      },
      user_name: {
        required,
      },
      password: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },

  async created() {
    this.loadCredentials();
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =
        type_uid === 1
          ? "Dashboard"
          : type_uid === 2
          ? "ManagerDashboard"
          : type_uid === 3
          ? "UserDashboard"
          : "/login";
      this.$router.push({ name: redirction });
    }
  },
  methods: {
    
    hideError: function () {
      this.errorMessage = null;
    },
    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
          const token = Math.floor(Math.random() * 10000);   // Normally obtained from your backend
            if (this.rememberMe) {
              this.saveCredentials(token);
            } else {
              this.clearCredentials();
            }
          try {
          const resData = await postData("login/userLogin", this.formFields);
          console.log("Response", resData);
          if (resData.status === 200 && resData.data.statuscode === 1) {
            /*
            if (resData.data.result.extension !== "") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              localStorage.setItem("ServerPath", "/ws");
              localStorage.setItem("EchoCancellation", "1");
              localStorage.setItem("VoiceMailSubscribe", "1");
              localStorage.setItem("SipDomain", "dailer.caretalkhosting.com");
              localStorage.setItem(
                "profileName",
                resData.data.result.extension
              );
              localStorage.setItem(
                "SipUsername",
                resData.data.result.extension
              );
              localStorage.setItem("AspectRatio", "1.33");
              localStorage.setItem("VideoSrcId", "default");
              localStorage.setItem("SipPassword", "SIP123ext");
              localStorage.setItem("loglevel:webpack-dev-server", "WARN");
              localStorage.setItem("AutoGainControl", "1");
              localStorage.setItem("wssServer", "dailer.caretalkhosting.com");
              localStorage.setItem(
                "RingOutputId",
                "724c6dad96e08e09da3ed3fb21ed79a3966a0e916eb4a267d108a02ae19ea4ee"
              );
              localStorage.setItem("WebSocketPort", "8089");
              localStorage.setItem("NoiseSuppression", "1");
              localStorage.setItem("language", "en");
              localStorage.setItem("WelcomeScreenAccept", "yes");
              localStorage.setItem("InstanceId", String(Date.now()));
              localStorage.setItem(
                "16750799442502185-Buddies",
                '{"TotalRows":0,"DataCollection":[]}'
              );
              localStorage.setItem("profileUserID", "167508055739511B1");
              localStorage.setItem("ChatEngine", "SIMPLE");
              localStorage.setItem("AudioOutputId", "default");
              localStorage.setItem("AudioSrcId", "default");
              localStorage.setItem("VideoOrientation", "rotateY(180deg)");
              localStorage.setItem(
                "profileVcard",
                '{"TitleDesc":"","Mobile":"","Email":"","Number1":"","Number2":""}'
              );
              localStorage.setItem("Notifications", "0");
              localStorage.setItem(
                "167508055739511B1-Buddies",
                '{"TotalRows":0,"DataCollection":[]}'
              );
            } else {
              setTimeout(function () {
                ShowContacts();
              }, 3000);
            }
            */
            // Admin
            if (resData.data.result.type_uid === 1) {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({ name: "Dashboard" });
            }
            // MANAGER
            if (resData.data.result.type_uid === 2) {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({ name: "ManagerDashboard" });
            }
            // VA
            if (resData.data.result.type_uid === 3) {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );              
              this.$router.push({ name: "UserDashboard" });
            }
          } else {
            this.$router.push("/");
            this.errorMessage = resData.data.message;
            setTimeout(
              function () {
                this.hideError();
              }.bind(this),
              4000
            );
          }
        } catch (e) {
          this.error = e;
        }
      }
    },
    saveCredentials(token) {
      // Set cookies to expire in 7 days if "Remember Me" is checked
      Cookies.set('token', token, { expires: 7 });
      Cookies.set('type_uid', this.formFields.type_uid,{ expires: 7 }); // Store email for auto-fill (optional)
      Cookies.set('user_name', this.formFields.user_name,{ expires: 7 }); // Store email for auto-fill (optional)
      Cookies.set('password', this.formFields.password,{ expires: 7 }); // Store email for auto-fill (optional)
    },

    loadCredentials() {
      // Load credentials from cookies if they exist
      const type_uid = Cookies.get('type_uid');
      const user_name = Cookies.get('user_name');
      const password = Cookies.get('password');
      const token = Cookies.get('token');
      
      if (token) {
        // Automatically authenticate user using the token
        // For example, call autoLogin(token) function here.
        console.log('Token found. Auto-login using token.');
      }

      if(password) {
        // Pre-fill the email field for convenience
        this.formFields.type_uid=type_uid;
        this.formFields.user_name=user_name;
        this.formFields.password = password;
        this.rememberMe = true; // Set checkbox to true if cookies were found
      }
    },

    clearCredentials() {
      // Remove cookies if "Remember Me" is not checked
      Cookies.remove('token');
      Cookies.remove('type_uid');
      Cookies.remove('user_name');
      Cookies.remove('password');
    }

  },
};
</script>
<style scoped>
.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}
.hClass span {
  background: #fff;
  padding: 0 10px;
}
</style>
