<template>
  <nav>
    <div class="header-logo">
      <img src="../../assets/img/lvc-logo.png" />
    </div>
    <div class="header-logo headerClone">
      <img alt="" src="../../assets/img/lvc-logo.png" />
    </div>
    <ul :id="this.loginUser.type_uid" class="menu-outer" v-if="this.loginUser.type_uid == '1'">
      <li>
        <a href="#" class="menu-link" @click="menuClick('Dashboard')" :class="{ active: nameUrl == 'Dashboard' }"><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Dashboard</span></a></li>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('LeadRecords')"
          :class="{ active:nameUrl == 'LeadRecords' }"
          ><span class="bi-file-earmark-person-fill menu-icon"></span>
          <span class="menu-name">Patients</span></a>
      </li>
      <li>
        <a href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#super_enrollment"
          aria-controls="super_enrollment"
          :class="{active:nameUrl == 'ManagerRecords' ||nameUrl == 'UserRecords',}"
          :aria-expanded="{true: nameUrl == 'ManagerRecords' || nameUrl == 'UserRecords',}"
          ><span class="bi-person-vcard-fill menu-icon"></span>
          <span class="menu-name">Users<span class="dropicon"><img src="../../assets/img/chevron-down.svg" alt="" /></span></span></a>
      </li>
      <ul class="innerDropdownMenu collapse multi-collapse" id="super_enrollment" :class="{show : nameUrl == 'ManagerRecords' || nameUrl == 'UserRecords'}" :data-bs-parent="'#'+this.loginUser.type_uid">
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('ManagerRecords')"
            :class="{ active: nameUrl == 'ManagerRecords' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Patient Manager</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('UserRecords')"
            :class="{ active: nameUrl == 'UserRecords' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Assistant</span></a>
        </li>
    </ul>
    <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('AssignExtension')"
          :class="{ active: nameUrl == 'AssignExtension' }"
          ><span class="bi-file-earmark-person-fill menu-icon"></span>
          <span class="menu-name">VoIP Phone</span></a
        >
      </li>
      <li>
        <a href="#"
          class="menu-link"
          @click="menuClick('Setting')"
          :class="{ active: nameUrl == 'Setting' }"
          ><span class="bi-file-earmark-person-fill menu-icon"></span>
          <span class="menu-name">Settings</span></a
        >
      </li>
      <li>
        <a href="#"
          class="menu-link"
          @click="menuClick('CrmApi')"
          :class="{ active: nameUrl == 'CrmApi' }"
          ><span class="bi-file-earmark-person-fill menu-icon"></span>
          <span class="menu-name">Integration</span></a
        >
      </li>
      <!-- <li>
        <a href="#"
          class="menu-link"
          @click="menuClick('AssignDB')"
          :class="{ active: nameUrl == 'AssignDB' }"
          ><span class="bi-file-earmark-person-fill menu-icon"></span>
          <span class="menu-name">AssignDB</span></a>
      </li> -->
    </ul>
    <ul :id="this.loginUser.type_uid" class="menu-outer" v-if="this.loginUser.type_uid == '2'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('ManagerDashboard')"
          :class="{ active: nameUrl == 'ManagerDashboard' }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Dashboard</span></a>
      </li>
      <li>
        <a 
          href="#"
          class="menu-link"
          @click="menuClick('ManagerLeadRecords')"
          :class="{ active: nameUrl == 'ManagerLeadRecords' }"
          ><span class="bi-file-earmark-person-fill menu-icon"></span>
          <span class="menu-name">Patients</span></a>
      </li>
    </ul>
    <ul :id="this.loginUser.type_uid" class="menu-outer" v-if="this.loginUser.type_uid == '3'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('UserDashboard')"
          :class="{ active: nameUrl == 'UserDashboard' }"
          ><span class="bi bi-house-door-fill menu-icon"></span>
          <span class="menu-name">Home</span></a>
      </li>
      <!-- <li>
        <a href="#"
          class="menu-link"
          @click="menuClick('ManagerLeadRecords')"
          :class="{ active: nameUrl == 'ManagerLeadRecords' }"
          ><span class="bi-file-earmark-person-fill menu-icon"></span>
          <span class="menu-name">Patients</span></a
        >
      </li> -->
    </ul>
  </nav>
</template>
<script>
import { useRoute } from "vue-router";
export default {
  name: "Navbar",
  data() {
    return {
      nameUrl: null,
      loginUser: JSON.parse(localStorage.getItem("LogIn")),
    };
  },
  mounted() {
    this.nameUrl = this.$route.name;
  },
  methods: {
    menuClick(url) {
      if ($(window).width() <= 800) {
        $("nav").toggleClass("sidebarClone");
      }
      this.$router.push({ name: url });
      this.nameUrl = url;
    },
  },
};

</script>
