<template>
  <div>
    <form @submit.prevent="login"><br/>
      <input type="email" v-model="email" placeholder="Email" required /><br/>
      <input type="password" v-model="password" placeholder="Password" required /><br/>
      <label>
        <input type="checkbox" v-model="rememberMe" /> 
      </label><br/>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  data(){
    return {
      email:'',
      password:'',
      rememberMe:false,
    };
  },
  mounted(){
    this.loadCredentials();
  },
  methods: {
    login() {
      // Mock API call or authentication logic
      const token = '1234567'; //Normally obtained from your backend
      if (this.rememberMe) {
        this.saveCredentials(token);
      } else {
        this.clearCredentials();
      }
      // Proceed with login logic (redirect,store session, etc.)
      console.log("Logged in successfully");
    },

    saveCredentials(token) {
      // Set cookies to expire in 7 days if "Remember Me" is checked
      Cookies.set('token', token, { expires: 7 });
      Cookies.set('password', this.password,{expires:7 }); // Store email for auto-fill (optional)
    },

    loadCredentials() {
      // Load credentials from cookies if they exist
      const password = Cookies.get('password');
      const token = Cookies.get('token');
      if(token){
        // Automatically authenticate user using the token
        // For example, call autoLogin(token) function here.
        console.log('Token found. Auto-login using token.');
      }
      if (password){
        // Pre-fill the email field for convenience
        this.password = password;
        this.rememberMe = true; // Set checkbox to true if cookies were found
      }
    },

    clearCredentials() {
      // Remove cookies if "Remember Me" is not checked
      Cookies.remove('token');
      Cookies.remove('password');
    }
  }
}
</script>

